"use strict";

import textset from "@/settings/textset.js";

import Button from "@/components/Button.vue";
import List from "@/components/List.vue";
import Modal from "@/components/Modal.vue";

import firebase from "@/mixins/firebase.js";
import modal from "@/mixins/modal.js";
import storage from "@/mixins/storage.js";

export default {
    "components": {
        Button,
        List,
        Modal,
    },
    "mixins": [
        firebase,
        modal,
        storage,
    ],
    data() {
        return {
            "column": "",
            "management": this.$store.getters.getManagements[this.name],
            "readData": [],
            "row": [],
            "textset": textset,
            "title": "",
        };
    },
    "computed": {
        $_pagetitle() {
            const title = this.title;
            const textset = this.textset[this.management.textset];
            return !this.history ? `${title}${textset.list}` : `${title}${textset.history}${textset.list}`;
        },
    },
    created() {
        this.$store.watch(
            (state, getters) => getters.getManagements,
            () => {
                this.management = this.$store.getters.getManagements[this.name];
            }
        );
        this.$_created();
    },
    "methods": {
        $_created() {
            this.$_reset();
            this.$_read().catch(error => {
                console.error(error.message);
                this.readData = ["none"];
                this.$_modalError("read");
            });
            this.watch = this.name;
        },
        $_reset() {
            this.readData = [];
            this.management = this.$store.getters.getManagements[this.name];
            this.title = this.management.title;
            this.column = this.management.column;
            // 履歴は完了した日時を埋め込む
            if (this.history) {
                const column = this.column.slice();
                const val = this.management.completed;
                const n = this.column.length;
                column.splice(n - 1, 0, val);
                this.column = column;
            }
        },
        $_goCreate() {
            this.$router.push(this.$_link("create", this.name));
        },
        $_goHistory() {
            this.$router.push({
                "name": `${this.name}_history_list`,
            });
        },
        $_read() {
            const readData = masterData => {
                return this.$_firestoreRead({
                    "collection": this.management.collection,
                    "collectionGroup": this.management.collectionGroup,
                    "doc": this.id,
                    "where": this.history ? [[this.management.completed, "!=", ""]] : this.management.completed ? [[this.management.completed, "==", ""]] : "",
                }).then(response => {
                    const readObject = this.$_firestoreReadListObject(response);

                    if (masterData) {
                        const masterObject = this.$_firestoreReadListObject(masterData);
                        for (const key in readObject) {
                            const masterId = readObject[key].userId;
                            readObject[key] = {
                                ...readObject[key],
                                ...masterObject[masterId],
                            };
                        }
                    }

                    const data = this.$_firestoreReadListArray(readObject);
                    const process = [];
                    for (const index in data) {
                        if (data[index].image && data[index].image[0]) {
                            const image = data[index].image;
                            const fileProcessData = {
                                "fileName": image,
                            };
                            process.push(this.$_fileDownload(fileProcessData).then(response => {
                                data[index].image = response;
                            }));
                        }
                    }
                    if (process.length > 0) {
                        Promise.all(process).then(() => {
                            this.readData = data;
                        });
                    }
                    else {
                        this.readData = data.length > 0 ? data : ["none"];
                    }
                });
            };

            const master = [];
            // nestかconnectがあればcollectionよりマスタデータを取得して表示用に仕込む
            const management = this.management;
            if (management.nest || management.connect) {
                const collection = management.collection;
                master.push(
                    this.$_firestoreRead({
                        "collection": collection,
                        "doc": [],
                    })
                );
            }
            // :userIdがあればマスタデータを取得して表示用に仕込む
            else if (this.name.indexOf(":userId") > 0) {
                const collection = "users";
                const doc = [this.id[0]];
                this.$_firestoreRead({
                    "collection": collection,
                    "doc": doc,
                }).then(response => {
                    const data = response.data();
                    this.title = data["name"] ? `${data["name"]}の${management.title}` : management.title;
                });
            }

            if (master.length > 0) {
                return Promise.all(master).then(response => {
                    return readData(response[0]);
                });
            }
            else {
                return readData();
            }
        },
    },
};
