<template>
    <div v-if="management.use" class="content">
        <h1 class="pagetitle">
            {{ pagetitle }}
        </h1>
        <table :class="$style.list">
            <Sort
                :column="column"
                :list="row.read"
                :name="name"
                @sort-list="row.sort = $event"
            />
            <template v-if="!loader && readData[0] !== 'none'">
                <template v-for="(data, index) in row.sort">
                    <tr v-if="!data.deletedAt" :key="`tr${index}`" :class="$style[addIsClass(data)]" @click="modalForm(data)">
                        <td>
                            <img :src="data.image[0]">
                        </td>
                        <td>
                            <span>
                                お預かり
                            </span><br>
                            {{ data.receptionDate }}
                        </td>
                        <td v-if="data.reservationDate">
                            <span>
                                返却予定
                            </span><br>
                            {{ data.reservationDate }}<br>
                            {{ data.reservationTime }}
                        </td>
                    </tr>
                </template>
            </template>
        </table>
        <div v-if="loader" :class="$style.loader">
            <Loader
                :loader="loader"
            />
        </div>
        <Modal
            :modal="modal"
        />
    </div>
</template>

<script>
    "use strict";

    import config from "@/settings/config.js";
    import field from "@/settings/field.js";

    import Loader from "@/components/Loader.vue";
    import Sort from "@/components/Sort.vue";

    import filters from "@/mixins/filters.js";
    import firebase from "@/mixins/firebase.js";
    import list from "@/mixins/list.js";

    export default {
        "components": {
            Loader,
            Sort,
        },
        "mixins": [
            filters,
            firebase,
            list,
        ],
        "props": {
            "name": {
                "type": String,
                "required": false,
                "default": "",
            },
        },
        data() {
            return {
                "config": config,
                "field": field,
                "id": [this.$_getUid()],
                "loader": true,
                "readClosedDateData": [],
                "readReceptionTimeData": {},
                "row": {
                    "read": [],
                    "sort": [],
                },
                "userData": {},
            };
        },
        "computed": {
            pagetitle() {
                const textset = this.textset[this.management.textset];
                return `${this.management.title}${textset.list}`;
            },
        },
        "watch": {
            "readData": function() {
                if (this.readData[0] === "none") {
                    this.loader = false;
                }
                else if (this.readData.length > 0) {
                    this.row.read = this.readData;
                    this.loader = false;
                }
            },
        },
        created() {
            this.$_reset();
            this.$_firestoreSnapshot({
                "collection": this.management.collection,
                "doc": this.id,
                "callback": () => {
                    this.$_read();
                    this.readClosedDate();
                    this.readReceptionTime();
                },
            });
            this.$_firestoreRead({
                "collection": "users",
                "doc": this.id,
            }).then(response => {
                this.userData = response.data();
            }).catch(error => {
                console.error(error.message);
            });
        },
        "methods": {
            addIsClass(data) {
                if (data.reservationDate || data.reservationTime) {
                    return "isReservation";
                }
            },
            readClosedDate() {
                this.$_firestoreRead({
                    "collection": "closedDates",
                    "doc": [],
                }).then(response => {
                    const object = this.$_firestoreReadListObject(response);
                    this.readClosedDateData = this.$_firestoreReadListArray(object);
                }).catch(error => {
                    console.error(error.message);
                });
            },
            readReceptionTime() {
                this.$_firestoreRead({
                    "collection": "receptionTimes",
                    "doc": [],
                }).then(response => {
                    this.readReceptionTimeData = this.$_firestoreReadListObject(response);
                }).catch(error => {
                    console.error(error.message);
                });
            },
            modalForm(data) {
                if (data.reservationDate || data.reservationTime) {
                    alert("返却予定日時は登録済みです。変更の場合は店舗へご連絡ください。");
                }
                else {
                    this.$_modalOpen({
                        "data": {
                            "message": {
                                "start": "返却希望日時を入力してください。",
                                "end": "返却日時を登録しました！",
                                "error": "返却日時の登録に失敗しました。",
                            },
                            "button": {
                                "primary": "登録する",
                                "cancel": "キャンセル",
                                "finished": "閉じる",
                                "error": "閉じる",
                            },
                        },
                        "form": "inputReservationDatetime",
                        "editMode": "create",
                        "callback": {
                            "start": modalData => {
                                const inputDate = modalData.reservationDate;
                                const inputTime = modalData.reservationTime;
                                const dateTime = new Date(`${inputDate}T${inputTime}`);
                                const week = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"][dateTime.getDay()];

                                const dayOfWeekSstartTime = this.readReceptionTimeData.week[week].startTime;
                                const dayOfWeekEndTime = this.readReceptionTimeData.week[week].endTime;
                                
                                const time = dateTime.getTime();
                                const startDateTime = new Date(`${inputDate}T${dayOfWeekSstartTime}`);
                                const startTime = startDateTime.getTime();
                                const endDateTime = new Date(`${inputDate}T${dayOfWeekEndTime}`);
                                const endTime = endDateTime.getTime();
                                const today = new Date();

                                const doc = [data.id.userId, data.id.updateId];
                                let set = {};

                                if (today < dateTime) {
                                    if (this.readClosedDateData.some(item => item.closedDate === modalData.reservationDate)) {
                                        alert("休業日のため、別の日時を選択してください。");
                                        this.modal.loader = true;
                                        this.modal.loader = false;
                                    }
                                    else {
                                        if ((startTime && endTime) && (startTime <= time && time <= endTime)) {
                                            set = modalData;
                                            set.orderDatetime = this.$_firestoreTimestamp();
                                            this.$_firestoreUpdate({
                                                "collection": this.management.collection,
                                                "doc": doc,
                                                "set": set,
                                            }).then(() => {
                                                this.$_modalFinished();
                                                const mailData = this.config.mail.reservation;
                                                mailData.text = mailData.text.replace(/｛ニックネーム｝/g, this.userData.name);
                                                mailData.text = mailData.text.replace(/｛お客様ID｝/g, this.id);
                                                mailData.text = mailData.text.replace(/｛返却予定日｝/g, inputDate);
                                                mailData.text = mailData.text.replace(/｛返却予定時間｝/g, inputTime);
                                                this.$_functionsSendMail(mailData).catch(error => {
                                                    console.error(error.message);
                                                });
                                            }).catch(() => {
                                                this.$_modalError();
                                            });
                                        }
                                        else {
                                            alert("選択された日時は受付時間外です。");
                                            this.modal.loader = true;
                                            this.modal.loader = false;
                                        }
                                    }
                                }
                                else {
                                    alert("翌日以降を選択してください。");
                                    this.modal.loader = true;
                                    this.modal.loader = false;
                                }
                            },
                            "end": () => {
                                this.$_modalClose();
                            },
                        },
                    });
                }
            },
        },
    };
</script>

<style lang="scss" module>
    $content-text-color: #4c4c4c;

    .list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        line-height: 1.4;
        width: 100%;
        tr {
            background: #fff;
            border: 1px solid #ddd;
            display: flex;
            flex-direction: column;
            margin: 0 0 4%;
            width: 48%;
            &:first-child {
                display: none;
            }
            &:not(:first-child) {
                &:hover {
                    background: rgba($theme-color, .15);
                    border-color: rgba($theme-color, .4);
                    color: $content-text-color;
                }
            }
            &.isReservation {
                background: #efefef;
            }
        }
        th {
            display: block;
            &:not(:first-child) {
                padding-top: 4px;
            }
        }
        td {
            padding: 0 8px 4px;
            &:first-child {
                padding: 0 0 8px;
            }
            &:last-child {
                padding-bottom: 8px;
            }
            > img {
                height: 150px;
                object-fit: cover;
                width: 100%;
            }
            > span {
                font-size: 14px;
                font-weight: bold;
            }
        }
    }

    .loader {
        padding: 32px;
        text-align: center;
    }
</style>
