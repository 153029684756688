<template>
    <div v-if="module.use" class="l-5-11-b--pc l-2-12-b--sp" :class="$style.searchFilter">
        <template v-for="(data, key, index) in filter">
            <div :key="`input${index}`" :class="$style.searchFilter__item">
                <p :class="$style.searchFilter__item__head">
                    {{ field[key].head }}
                </p>
                <input v-model="input[key]" :class="$style.searchFilter__item__input" type="search">
            </div>
        </template>
        <div v-if="watch"></div>
    </div>
</template>

<script>
    "use strict";

    import field from "@/settings/field.js";
    import datetime from "@/mixins/datetime.js";

    export default {
        "mixins": [
            datetime,
        ],
        "props": {
            "column": {
                "type": Array,
                "required": true,
            },
            "list": {
                "type": Array,
                "required": true,
            },
            "name": {
                "type": String,
                "required": true,
            },
            "params": {
                "type": Object,
                "required": true,
            },
        },
        data() {
            return {
                "field": field,
                "filter": {},
                "input": {},
                "module": this.$store.getters.getModules.filter,
            };
        },
        "computed": {
            watch() {
                this.handleSearch();
                return false;
            },
        },
        "watch": {
            "list": function() {
                this.setModule();
            },
            "params": function() {
                this.setInputData();
            },
        },
        created() {
            this.$store.watch(
                (state, getters) => getters.getModules,
                () => {
                    this.module = this.$store.getters.getModules.filter;
                    this.setModule();
                }
            );
        },
        "methods": {
            setModule() {
                if (this.module.use) {
                    this.setFilter();
                    this.filterList();
                }
                else {
                    this.$emit("filter-list", this.list);
                }
            },
            setFilter() {
                const option = this.module.option[this.name];
                const filter = option ? JSON.parse(JSON.stringify(option)) : {};
                if (!option) {
                    for (const key of this.column) {
                        if (key !== "image" && key !== "&nbsp") {
                            const type = this.field[key].filter ? this.field[key].filter : "";
                            filter[key] = type;
                        }
                    }
                }
                this.input = {};
                for (const key in filter) {
                    this.$set(this.input, key, "");
                }
                this.filter = filter;
            },
            setInputData() {
                for (const key in this.params) {
                    if (key in this.input) {
                        // paramsをinputにset
                        this.input[key] = this.params[key];
                    }
                }
            },
            // 検索項目でフィルター
            filterList() {
                const list = [];
                for (const data of this.list) {
                    let flag = false;
                    for (const key in this.filter) {
                        if (!data[key]) {
                            data[key] = "";
                        }
                        const text = data[key].seconds ? this.$_getDateTime(data[key].seconds * 1000).db : String(data[key]);
                        if (this.filter[key] === "exact") {
                            // 完全一致
                            if (this.input[key] === "" || this.input[key] === text) {
                                flag = true;
                            }
                            else {
                                flag = false;
                                break;
                            }
                        }
                        else {
                            // 部分一致
                            if (this.input[key] === "" || text.indexOf(this.input[key]) !== -1) {
                                flag = true;
                            }
                            else {
                                flag = false;
                                break;
                            }
                        }
                    }
                    if (flag) {
                        list.push(data);
                    }
                }
                this.$emit("filter-list", list);
            },
            handleSearch() {
                if (this.list.length > 0) {
                    this.filterList();
                    this.$emit("input", this.input);
                }
            },
        },
    };
</script>

<style lang="scss" module>
    .searchFilter {
        margin: 0 0 48px;
        &__item {
            &__head {
                font-size: 21px;
                padding-bottom: 8px;
                @include sp {
                    font-size: 16px;
                }
            }
            &__input {
                border-color: #999;
            }
        }
    }
</style>
