"use strict";

export default {
    "filters": {
        // 表示文字を省略
        $_textEllipsis(data) {
            let text = "";
            if (data.value && data.length) {
                text = data.length < data.value.length ? `${data.value.slice(0, data.length)}…` : data.value;
            }
            else {
                text = data.value ?? "";
            }
            return text;
        },
    },
};
