<template>
    <div>
        <Tab
            :history="history"
            :list="row.read"
            :name="name"
            @tab-list="row.tab = $event"
        />
        <SearchFilter
            :column="column"
            :list="row.tab"
            :name="name"
            :params="params"
            @filter-list="row.filter = $event"
            @input="setParams"
        />
        <div v-if="pager.use || filter.use" :class="$style.listStatus">
            <div v-if="filter.use">
                検索結果：{{ row.filter.length }}件
            </div>
            <div v-if="pager.use">
                {{ pagerTotal }}ページ中 {{ pagerNumber }}ページ目を表示
            </div>
        </div>
        <table>
            <Sort
                :column="column"
                :list="row.filter"
                :name="name"
                @sort-list="setResult($event)"
            />
            <template v-if="!loader">
                <tr v-for="(data, index) in row.pager" :key="`tr${index}`" :class="$style[data.class]">
                    <template v-for="(key, index) in column">
                        <td :key="`td${index}`" :class="field[key].class">
                            <template v-if="key === '&nbsp'">
                                <template v-if="$store.getters.getManagements[name].button.status">
                                    <Button
                                        v-if="$store.getters.getManagements[name].button.status.return && !history"
                                        class-name="buttonPrimary"
                                        :text="`${text.status.return}にする`"
                                        @action="modal('return', data)"
                                    />
                                </template>
                                <template v-if="$store.getters.getManagements[name].nest">
                                    <Button
                                        v-for="(key, index) in $store.getters.getManagements[name].nest"
                                        :key="`nestButton${index}`"
                                        class-name="buttonOther"
                                        :text="`${$store.getters.getManagements[key].title}一覧`"
                                        @action="goNest(data.id, key)"
                                    />
                                </template>
                                <Button
                                    v-if="$store.getters.getManagements[name].button.detail || history"
                                    :text="text.detail"
                                    type="detail"
                                    @action="goDetail(data.id)"
                                />
                                <Button
                                    v-if="$store.getters.getManagements[name].button.update && !history"
                                    :text="text.update"
                                    type="update"
                                    @action="goUpdate(data.id)"
                                />
                            </template>
                            <template v-else-if="key === 'image'">
                                <img :src="data[key][0]" />
                            </template>
                            <template v-else-if="key === 'updateId'">
                                {{ data.id.updateId }}
                            </template>
                            <template v-else>
                                <template v-if="field[key].type === 'timestamp'">
                                    <template v-if="!data.deletedAt">
                                        {{ data[key] ? $_getDateTime(data[key].seconds * 1000).db : data[key] }}
                                    </template>
                                </template>
                                <template v-else>
                                    {{ { "value": data[key], "length": field[key].ellipsis } | $_textEllipsis }}
                                </template>
                            </template>
                        </td>
                    </template>
                </tr>
            </template>
        </table>
        <div v-if="loader" :class="$style.loader">
            <Loader
                :loader="loader"
            />
        </div>
        <Pager
            :list="row.sort"
            :name="name"
            @pager-list="row.pager = $event"
            @pager-number="pagerNumber = $event"
            @pager-total="pagerTotal = $event"
        />
    </div>
</template>

<script>
    "use strict";

    import field from "@/settings/field.js";

    import Button from "@/components/Button.vue";
    import Loader from "@/components/Loader.vue";
    import Pager from "@/components/Pager.vue";
    import SearchFilter from "@/components/SearchFilter.vue";
    import Sort from "@/components/Sort.vue";
    import Tab from "@/components/Tab.vue";

    import datetime from "@/mixins/datetime.js";
    import filters from "@/mixins/filters.js";

    let flagHistory = false;

    export default {
        "components": {
            Button,
            Loader,
            Pager,
            SearchFilter,
            Sort,
            Tab,
        },
        "mixins": [
            datetime,
            filters,
        ],
        "props": {
            "column": {
                "type": Array,
                "required": true,
            },
            "history": {
                "type": Boolean,
                "required": false,
                "default": false,
            },
            "name": {
                "type": String,
                "required": true,
            },
            "read": {
                "type": Array,
                "required": true,
            },
            "text": {
                "type": Object,
                "required": true,
            },
        },
        data() {
            return {
                "field": field,
                "filter": this.$store.getters.getModules.filter,
                "loader": true,
                "pager": this.$store.getters.getModules.pager,
                "pagerNumber": 0,
                "pagerTotal": 0,
                "params": {},
                "row": {
                    "filter": [],
                    "history": [],
                    "pager": [],
                    "read": [],
                    "sort": [],
                    "tab": [],
                },
            };
        },
        "watch": {
            "history": function() {
                this.reset();
            },
            "read": function() {
                this.reset();
            },
        },
        created() {
            flagHistory = false;
            window.addEventListener("popstate", () => {
                window.location.reload();
            });
            this.setParams();
            this.$store.watch(
                (state, getters) => getters.getModules,
                () => {
                    this.filter = this.$store.getters.getModules.filter;
                    this.pager = this.$store.getters.getModules.pager;
                }
            );
        },
        "methods": {
            setResult(data) {
                this.row.sort = data;
                this.$emit("result", data);
            },
            setParams(data) {
                if (this.filter.use) {
                    if (!data) {
                        this.params = {};
                    }
                    const value = data ? data : this.$route.query;
                    Object.assign(this.params, value);
                    if (data) {
                        this.pushHistory();
                    }
                }
            },
            pushHistory() {
                if (flagHistory) {
                    const name = !this.history ? `${this.name}_list` : `${this.name}_history_list`;
                    this.$router.push({
                        "name": name,
                        "query": this.params,
                    }, () => {}, () => {});
                }
                else {
                    flagHistory = true;
                }
            },
            reset() {
                flagHistory = false;
                this.setParams();
                this.row.read = [];
                this.row.tab = [];
                this.row.filter = [];
                this.row.sort = [];
                this.row.pager = [];
                this.loader = true;
                if (this.read[0] === "none") {
                    this.loader = false;
                }
                else if (this.read.length > 0) {
                    const list = this.read;
                    if (this.history) {
                        for (const index in list) {
                            if (list[index].deletedAt) {
                                list[index].class = "isDelete";
                            }
                        }
                    }
                    this.row.read = list;
                    this.loader = false;
                }
            },
            goUpdate(id) {
                let value = {};
                if (!this.history) {
                    value = {
                        "name": `${this.name}_update`,
                        "params": {
                            "userId": id.userId,
                            "updateId": id.updateId,
                        },
                    };
                }
                this.$router.push(value);
            },
            goDetail(id) {
                let value = {};
                let name = this.name;
                if (!this.history) {
                    value = {
                        "name": `${name}_detail`,
                        "params": {
                            "userId": id.userId,
                            "updateId": id.updateId,
                        },
                    };
                }
                else {
                    value = {
                        "name": `${name}_history_detail`,
                        "params": {
                            "userId": id.userId,
                            "updateId": id.updateId,
                        },
                    };
                }
                this.$router.push(value);
            },
            goNest(id, key) {
                const value = {
                    "name": `${key}_list`,
                    "params": {
                        "userId": id.updateId,
                    },
                };
                this.$router.push(value);
            },
            modal(type, itemData) {
                const managements = this.$store.getters.getManagements[this.name];
                let collection = "";
                if (!managements.collectionGroup) {
                    collection = managements.collection;
                }
                else {
                    collection = `${managements.collection}/${managements.collectionGroup}`;
                }
                const data = {
                    "collection": collection,
                    "itemData": itemData,
                    "type": type,
                };
                this.$emit("modal", data);
            },
        },
    };
</script>

<style lang="scss" module>
    .loader {
        padding: 32px;
        text-align: center;
    }

    .listStatus {
        line-height: 1.6;
        margin: 0 0 48px;
        @include sp {
            margin: 0 0 16px;
        }
    }

    table {
        .isDelete {
            td {
                color: #999;
                text-decoration: line-through;
            }
        }
        .isWarning {
            td {
                background: #982a2a;
                color: #fff;
            }
        }
    }
</style>
