"use strict";

export default {
    "methods": {
        // 全角英数字を半角へ・半角カタカナと一部記号を全角へ清書
        $_clearCopy(text) {
            const string = String(text).replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) => {
                return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
            });
            const katakanaMap = {
                "ｶﾞ": "ガ", "ｷﾞ": "ギ", "ｸﾞ": "グ", "ｹﾞ": "ゲ", "ｺﾞ": "ゴ",
                "ｻﾞ": "ザ", "ｼﾞ": "ジ", "ｽﾞ": "ズ", "ｾﾞ": "ゼ", "ｿﾞ": "ゾ",
                "ﾀﾞ": "ダ", "ﾁﾞ": "ヂ", "ﾂﾞ": "ヅ", "ﾃﾞ": "デ", "ﾄﾞ": "ド",
                "ﾊﾞ": "バ", "ﾋﾞ": "ビ", "ﾌﾞ": "ブ", "ﾍﾞ": "ベ", "ﾎﾞ": "ボ",
                "ﾊﾟ": "パ", "ﾋﾟ": "ピ", "ﾌﾟ": "プ", "ﾍﾟ": "ペ", "ﾎﾟ": "ポ",
                "ｳﾞ": "ヴ", "ﾜﾞ": "ヷ", "ｦﾞ": "ヺ",
                "ｱ": "ア", "ｲ": "イ", "ｳ": "ウ", "ｴ": "エ", "ｵ": "オ",
                "ｶ": "カ", "ｷ": "キ", "ｸ": "ク", "ｹ": "ケ", "ｺ": "コ",
                "ｻ": "サ", "ｼ": "シ", "ｽ": "ス", "ｾ": "セ", "ｿ": "ソ",
                "ﾀ": "タ", "ﾁ": "チ", "ﾂ": "ツ", "ﾃ": "テ", "ﾄ": "ト",
                "ﾅ": "ナ", "ﾆ": "ニ", "ﾇ": "ヌ", "ﾈ": "ネ", "ﾉ": "ノ",
                "ﾊ": "ハ", "ﾋ": "ヒ", "ﾌ": "フ", "ﾍ": "ヘ", "ﾎ": "ホ",
                "ﾏ": "マ", "ﾐ": "ミ", "ﾑ": "ム", "ﾒ": "メ", "ﾓ": "モ",
                "ﾔ": "ヤ", "ﾕ": "ユ", "ﾖ": "ヨ",
                "ﾗ": "ラ", "ﾘ": "リ", "ﾙ": "ル", "ﾚ": "レ", "ﾛ": "ロ",
                "ﾜ": "ワ", "ｦ": "ヲ", "ﾝ": "ン",
                "ｧ": "ァ", "ｨ": "ィ", "ｩ": "ゥ", "ｪ": "ェ", "ｫ": "ォ",
                "ｯ": "ッ", "ｬ": "ャ", "ｭ": "ュ", "ｮ": "ョ",
                "｡": "。", "､": "、", "ｰ": "ー", "｢": "「", "｣": "」", "･": "・",
            };
            const reg = new RegExp("(" + Object.keys(katakanaMap).join("|") + ")", "g");
            return string.replace(reg, (match) => {
                return katakanaMap[match];
            }).replace(/ﾞ/g, "゛").replace(/ﾟ/g, "゜");
        },
        // 頭文字を大文字に変換
        $_initialUpperCase(text) {
            const upperCase = `${text[0].toUpperCase()}${text.slice(1)}`;
            return upperCase;
        },
        // テキストを比較する
        $_comparison(textA, textB) {
            const stringA = this.$_clearCopy(textA);
            const stringB = this.$_clearCopy(textB);
            const comparison = stringA.toUpperCase() === stringB.toUpperCase();
            return comparison;
        },
    },
};
