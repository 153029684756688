<template>
    <div v-if="module.use && name === 'order' && !history">
        <div :class="$style.tab">
            <div :class="[$style.tab__item, state === 'printed' ? $style.isActive : '']" @click="switchTab('printed')">
                {{ status.printed.text }}待ち（{{ status.printed.list.length }}件）
            </div>
            <div :class="[$style.tab__item, state === 'deposited' ? $style.isActive : '']" @click="switchTab('deposited')">
                {{ status.deposited.text }}待ち（{{ status.deposited.list.length }}件）
                <p v-if="status.deposited.note" :class="$style.errorMessage">
                    {{ status.deposited.text }}期日が過ぎたご注文が{{ countDeadline.deposited }}件あります。
                </p>
            </div>
            <div :class="[$style.tab__item, state === 'sent' ? $style.isActive : '']" @click="switchTab('sent')">
                {{ status.sent.text }}待ち（{{ status.sent.list.length }}件）
                <p v-if="status.sent.note" :class="$style.errorMessage">
                    {{ status.sent.text }}期日が過ぎたご注文が{{ countDeadline.sent }}件あります。
                </p>
            </div>
        </div>
        <h2 :class="$style.tabTitle">
            {{ status[state].text }}待ち
        </h2>
    </div>
</template>

<script>
    "use strict";

    import datetime from "@/mixins/datetime.js";

    export default {
        "mixins": [
            datetime,
        ],
        "props": {
            "history": {
                "type": Boolean,
                "required": false,
            },
            "list": {
                "type": Array,
                "required": true,
            },
            "name": {
                "type": String,
                "required": true,
            },
        },
        data() {
            return {
                "activeList": [],
                "countDeadline": {
                    "deposited": 0,
                    "sent": 0,
                },
                "module": this.$store.getters.getModules.tab,
                "state": "printed",
                "status": {
                    "deposited": {
                        "list": [],
                        "note": false,
                        "text": "入金",
                    },
                    "printed": {
                        "list": [],
                        "text": "印刷",
                    },
                    "sent": {
                        "list": [],
                        "note": false,
                        "text": "発送",
                    },
                },
            };
        },
        "watch": {
            "list": function() {
                this.setModule();
            },
        },
        created() {
            this.$store.watch(
                (state, getters) => getters.getModules,
                () => {
                    this.module = this.$store.getters.getModules.tab;
                    this.setModule();
                }
            );
        },
        "methods": {
            setModule() {
                this.state = "printed";
                if (!this.history) {
                    this.sortOrders();
                    this.setActiveList();
                }
                else {
                    this.$emit("tab-list", this.list);
                }
            },
            // タブ別リストを作成
            sortOrders() {
                let printed = [];
                let deposited = [];
                let sent = [];
                let countDeadlineDeposited = 0;
                let countDeadlineSent = 0;
                for (const data of this.list) {
                    // 削除されていないデータのみリストへ追加
                    if (!data.deletedAt) {
                        if (!data.printedDatetime) {
                            printed.push(data);
                        }
                        else if (!data.depositedDatetime && data.paymentMethod === "銀行振込（前払い）") {
                            // 期日の判定
                            const flag = this.$_deadline({
                                "date": data.orderDatetime ? data.orderDatetime.toDate() : "",
                                "workingDays": 10,
                            });
                            if (!flag) {
                                if (!this.status.deposited.note) {
                                    this.status.deposited.note = true;
                                }
                                data.class = "isWarning";
                                countDeadlineDeposited += 1;
                            }
                            this.countDeadline.deposited = countDeadlineDeposited;
                            deposited.push(data);
                        }
                        else if (!data.sentDatetime) {
                            let flag = true;
                            if (data.depositedDatetime) {
                                // 期日の判定
                                flag = this.$_deadline({
                                    "date": data.depositedDatetime.toDate(),
                                    "workingDays": 5,
                                    "holiday": [6, 0],
                                });
                            }
                            else {
                                // 期日の判定
                                flag = this.$_deadline({
                                    "date": data.printedDatetime.toDate(),
                                    "workingDays": 5,
                                    "holiday": [6, 0],
                                });
                            }
                            if (!flag) {
                                if (!this.status.sent.note) {
                                    this.status.sent.note = true;
                                }
                                data.class = "isWarning";
                                countDeadlineSent += 1;
                            }
                            this.countDeadline.sent = countDeadlineSent;
                            sent.push(data);
                        }
                    }
                }
                this.status.printed.list = printed;
                this.status.deposited.list = deposited;
                this.status.sent.list = sent;
            },
            // 表示用リスト
            setActiveList() {
                this.activeList = this.status[this.state].list;
                this.$emit("tab-list", this.activeList);
            },
            // タブ切り替え
            switchTab(state) {
                this.state = state;
                this.setActiveList();
            },
        },
    };
</script>

<style lang="scss" module>
    .tab {
        align-items: flex-end;
        display: flex;
        position: relative;
        &::before {
            background: #777;
            bottom: 0;
            content: "";
            display: block;
            height: 1px;
            left: 0;
            position: absolute;
            width: 100%;
        }
        &__item {
            background: #fff;
            border: 1px solid #777;
            border-bottom: 0;
            border-radius: 8px 8px 0 0;
            color: #555;
            // flex: 1;
            font-size: 18px;
            margin: 0 8px 0 0;
            padding: 20px 16px 16px;
            width: 305px;
            &:last-child {
                margin-right: 0;
            }
            &:hover {
                cursor: pointer;
            }
            &.isActive {
                background: #777;
                color: #fff;
                &:hover {
                    cursor: default;
                }
                .errorMessage {
                    color: #fff;
                }
            }
            .errorMessage {
                color: red;
            }
        }
    }

    .tabTitle {
        color: #666;
        font-size: 28px !important;
        font-weight: bold;
        margin: 40px 0 48px;
    }
</style>
