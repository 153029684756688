<template>
    <div v-if="module.use && calcPageTotal > 0" :class="[$style.pager, $_setStyle]">
        <div :class="$style.pager__back">
            <button v-if="pageNumberCurrent + length / 2 > length + 1 && length < calcPageTotal" class="isSpNone" :class="$style.pager__back__button" @click="calcPageList(1)">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M0 3.795l2.995-2.98 11.132 11.185-11.132 11.186-2.995-2.981 8.167-8.205-8.167-8.205zm18.04 8.205l-8.167 8.205 2.995 2.98 11.132-11.185-11.132-11.186-2.995 2.98 8.167 8.206z" />
                </svg>
                最初へ
            </button>
            <button v-if="pageNumberCurrent > 1" :class="$style.pager__back__button" @click="calcPageList(pageNumberCurrent - 1)">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
                </svg>
                前へ
            </button>
        </div>
        <div>
            <template v-for="pageNumber in calcPageTotal">
                <button v-if="calcPager(pageNumber)" :key="pageNumber" :class="[$style.pager__button, pageNumberClass[pageNumber]]" type="button" @click="calcPageList(pageNumber)">
                    {{ pageNumber }}
                </button>
            </template>
        </div>
        <div :class="$style.pager__next">
            <button v-if="pageNumberCurrent !== calcPageTotal" :class="$style.pager__next__button" @click="calcPageList(pageNumberCurrent + 1)">
                次へ
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
                </svg>
            </button>
            <button v-if="pageNumberCurrent + length / 2 <= calcPageTotal && length < calcPageTotal" class="isSpNone" :class="$style.pager__next__button" @click="calcPageList(calcPageTotal)">
                最後へ
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M0 3.795l2.995-2.98 11.132 11.185-11.132 11.186-2.995-2.981 8.167-8.205-8.167-8.205zm18.04 8.205l-8.167 8.205 2.995 2.98 11.132-11.185-11.132-11.186-2.995 2.98 8.167 8.206z" />
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
    "use strict";

    import style from "@/mixins/style.js";

    export default {
        "mixins": [
            style,
        ],
        "props": {
            "list": {
                "type": Array,
                "required": true,
            },
            "name": {
                "type": String,
                "required": true,
            },
        },
        data() {
            return {
                "length": 0,
                "line": 0,
                "module": this.$store.getters.getModules.pager,
                "pageNumberClass": [],
                "pageNumberCurrent": 1,
            };
        },
        "computed": {
            calcPageTotal() {
                return Math.ceil(this.list.length / this.line);
            },
        },
        "watch": {
            "list": function() {
                this.setModule();
            },
        },
        created() {
            this.$store.watch(
                (state, getters) => getters.getModules,
                () => {
                    this.module = this.$store.getters.getModules.pager;
                    this.setModule();
                }
            );
        },
        "methods": {
            setModule() {
                if (this.module.use) {
                    let length = 9;
                    let line = 10;
                    if (this.module.option[this.name]) {
                        length = this.module.option[this.name].length;
                        line = this.module.option[this.name].line;
                    }
                    this.length = length;
                    this.line = line;
                    this.calcPageList(1);
                }
                else {
                    this.$emit("pager-list", this.list);
                }
            },
            calcPageList(pageNumber) {
                const startNunber = (pageNumber - 1) * this.line;
                const endNunber = startNunber + this.line;
                const list = this.list.slice(startNunber, endNunber);
                this.calcPageNumberClass(list, pageNumber);
                this.$emit("pager-list", list);
                this.$emit("pager-number", pageNumber);
                this.$emit("pager-total", this.calcPageTotal);
                window.scrollTo({
                    "top": 0,
                });
            },
            calcPageNumberClass(list, pageNumber) {
                this.pageNumberClass = [];
                for (const i in list) {
                    this.$set(this.pageNumberClass, i, "");
                }
                this.pageNumberCurrent = pageNumber;
                this.pageNumberClass[pageNumber] = this.$style.isCurrent;
            },
            calcPager(pageNumber) {
                const pageNumberCurrent = this.pageNumberCurrent;
                const length = this.length;
                const calcPageTotal = this.calcPageTotal;
                const calcPager = pageNumberCurrent - length / 2 <= pageNumber && pageNumberCurrent + length / 2 > pageNumber;
                const calcFirstPage = length  >= pageNumberCurrent + length / 2 && length + 1 > pageNumber;
                const calcLastPage = calcPageTotal < pageNumberCurrent + length / 2 && calcPageTotal - length < pageNumber;
                return calcPager || calcFirstPage || calcLastPage;
            },
        },
    };
</script>

<style lang="scss" module>
    $color: #666;

    .pager {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 32px 0 48px;
        text-align: center;
        &__button {
            border: 1px solid $color;
            border-radius: 4px;
            color: $color;
            height: 30px;
            line-height: 1;
            margin: 0 4px;
            min-width: 30px;
            @include sp {
                display: none;
            }
            &.isCurrent {
                background: $color;
                border: none;
                color: #fff;
                @include sp {
                    display: block;
                }
            }
        }
        &__back {
            text-align: right;
            width: 160px;
            &__button {
                color: $color;
                font-size: .9em;
                line-height: 1;
                margin: 2px 1em 0 0;
                > svg {
                    fill: $color;
                    height: .9em;
                    transform: rotate(180deg);
                    vertical-align: top;
                    width: .9em;
                }
            }
        }
        &__next {
            text-align: left;
            width: 160px;
            &__button {
                color: $color;
                font-size: .9em;
                line-height: 1;
                margin: 2px 0 0 1em;
                > svg {
                    fill: $color;
                    height: .9em;
                    vertical-align: top;
                    width: .9em;
                }
            }
        }
    }

    @mixin style ($color) {
        &.pager {
            .pager {
                &__button {
                    border-color: $color;
                    color: $color;
                    &.isCurrent {
                        background: $color;
                        color: #fff;
                    }
                }
                &__back {
                    &__button {
                        color: $color;
                        > svg {
                            fill: $color;
                        }
                    }
                }
                &__next {
                    &__button {
                        color: $color;
                        > svg {
                            fill: $color;
                        }
                    }
                }
            }
        }
    }

    .isYellow {
        @include style ($yellow);
    }

    .isPink {
        @include style ($pink);
    }

    .isGreen {
        @include style ($green);
    }
</style>
