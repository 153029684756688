<template>
    <tr v-if="column">
        <template v-for="(key, index) in column">
            <th v-if="key !== '&nbsp'" :id="`th${index}`" :key="`th${index}`" :class="field[key].class" @click="sort(key, index, true)">
                <span :class="iconClass">
                    {{ field[key].head }}
                </span>
            </th>
            <th v-else :id="`th${index}`" :key="`th${index}`" :class="field[key].class">
                &nbsp;
            </th>
        </template>
    </tr>
</template>

<script>
    "use strict";

    import field from "@/settings/field.js";

    import string from "@/mixins/string.js";

    export default {
        "mixins": [
            string,
        ],
        "props": {
            "column": {
                "type": Array,
                "required": false,
                "default": function() {
                    return [];
                },
            },
            "list": {
                "type": Array,
                "required": true,
            },
            "name": {
                "type": String,
                "required": true,
            },
        },
        data() {
            return {
                "field": field,
                "iconClass": "",
                "module": this.$store.getters.getModules.sort,
                "option": {
                    "key": "",
                    "type": "",
                },
                "sortTypeToggle": true,
                "sortTypeToggleKey": "",
            };
        },
        "watch": {
            "list": function() {
                this.setModule();
            },
        },
        created() {
            this.$store.watch(
                (state, getters) => getters.getModules,
                () => {
                    this.module = this.$store.getters.getModules.sort;
                    this.setModule();
                }
            );
        },
        mounted() {
            this.reset();
            this.changeList();
        },
        "methods": {
            setModule() {
                this.reset();
                if (this.module.use) {
                    this.changeList();
                }
                else {
                    this.$emit("sort-list", this.list);
                }
            },
            reset() {
                const name = this.name;
                this.iconClass = "";
                this.option.key = "";
                this.option.type = "";
                if (this.module.option[name]) {
                    this.option.key = this.module.option[name].field;
                    this.option.type = this.module.option[name].type;
                }
            },
            changeList() {
                let key = this.column[0];
                let index = 0;
                if (this.option) {
                    if (this.option.key) {
                        key = this.option.key;
                        index = this.column.indexOf(key);
                    }
                }
                if (this.module.use) {
                    this.resetSortClass();
                    this.setIconClass();
                }
                this.resetSortTypeToggle();
                this.sort(key, index, false);
            },
            resetSortTypeToggle() {
                this.sortTypeToggle = true;
                this.sortTypeToggleKey = "";
            },
            resetSortClass() {
                for (const index in this.column) {
                    const th = document.getElementById(`th${index}`);
                    if (th) {
                        th.classList.remove("isAsc");
                        th.classList.remove("isDesc");
                    }
                }
            },
            setIconClass() {
                if (this.option) {
                    this.iconClass = this.option.icon ? this.option.icon : "icon-sort";
                }
            },
            setSortType(key) {
                let type = "";
                if (this.option) {
                    type = this.option.type ? this.option.type : "desc";
                }
                if (this.sortTypeToggleKey === key) {
                    this.sortTypeToggle = !this.sortTypeToggle;
                }
                else if (this.sortTypeToggle && !this.sortTypeToggleKey && type === "asc") {
                    this.sortTypeToggle = true;
                }
                else {
                    this.sortTypeToggle = false;
                }
                this.sortTypeToggleKey = key;
                return this.sortTypeToggle ? "asc" : "desc";
            },
            setSortMode(key) {
                const sort = this.field[key].sort;
                return sort === "number" ? sort : "";
            },
            setSortClass(type, index) {
                const th = document.getElementById(`th${index}`);
                const adjType = this.$_initialUpperCase(type);
                if (th) {
                    th.classList.add(`is${adjType}`);
                }
            },
            sort(key, index, click) {
                if (this.module.use || (!this.module.use && !click) ) {
                    const list = JSON.parse(JSON.stringify(this.list));
                    const type = this.setSortType(key);
                    const mode = this.setSortMode(key);
                    let compare = null;
                    this.resetSortClass();
                    this.setSortClass(type, index);
                    if (mode === "number") {
                        if (type === "desc") {
                            compare = (a, b) => {
                                const textA = a[key] ? Number(a[key]) : 0;
                                const textB = b[key] ? Number(b[key]) : 0;
                                return textB - textA;
                            };
                        }
                        else {
                            compare = (a, b) => {
                                const textA = a[key] ? Number(a[key]) : 0;
                                const textB = b[key] ? Number(b[key]) : 0;
                                return textA - textB;
                            };
                        }
                    }
                    else {
                        compare = (a, b) => {
                            const textA = a[key] ? a[key].seconds ? String(a[key].seconds) : a[key] : "";
                            const textB = b[key] ? b[key].seconds ? String(b[key].seconds) : b[key] : "";
                            if (textA < textB) {
                                if (type === "desc") {
                                    return 1;
                                }
                                else {
                                    return -1;
                                }
                            }
                            else if (textA > textB) {
                                if (type === "desc") {
                                    return -1;
                                }
                                else {
                                    return 1;
                                }
                            }
                            return 0;
                        };
                    }
                    this.$emit("sort-list", list.sort(compare));
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .icon-sort {
        cursor: pointer;
        display: inline-block;
        padding-left: 18px;
        position: relative;
        &::before,
        &::after {
            border-right: 2px solid #aaa;
            border-top: 2px solid #aaa;
            content: "";
            height: 8px;
            left: 0;
            position: absolute;
            top: 50%;
            width: 8px;
        }
        &::before {
            margin-top: -1px;
            transform: translateY(-8px) rotate(-45deg);
        }
        &::after {
            margin-top: -3px;
            transform: translateY(2px) rotate(135deg);
        }
        &--right {
            cursor: pointer;
            padding-right: 18px;
            position: relative;
            &::before,
            &::after {
                border-right: 2px solid #aaa;
                border-top: 2px solid #aaa;
                content: "";
                height: 8px;
                position: absolute;
                right: 0;
                top: 50%;
                width: 8px;
            }
            &::before {
                margin-top: -1px;
                transform: translateY(-8px) rotate(-45deg);
            }
            &::after {
                margin-top: -3px;
                transform: translateY(2px) rotate(135deg);
            }
        }
    }

    .icon-sort--triangle {
        cursor: pointer;
        padding-left: 18px;
        position: relative;
        &::before,
        &::after {
            border: 6px solid transparent;
            content: "";
            height: 0;
            left: 0;
            position: absolute;
            top: 50%;
            width: 0;
        }
        &::before {
            border-bottom-color: #aaa;
            margin-top: -15px;
        }
        &::after {
            border-top-color: #aaa;
            margin-top: 1px;
        }
        &--right {
            cursor: pointer;
            padding-right: 18px;
            position: relative;
            &::before,
            &::after {
                border: 6px solid transparent;
                content: "";
                height: 0;
                position: absolute;
                right: 0;
                top: 50%;
                width: 0;
            }
            &::before {
                border-bottom-color: #aaa;
                margin-top: -15px;
            }
            &::after {
                border-top-color: #aaa;
                margin-top: 1px;
            }
        }
    }

    .isAsc {
        .icon-sort,
        .icon-sort--right {
            &::before {
                border-right-color: #4c4c4c;
                border-top-color: #4c4c4c;
            }
        }
        .icon-sort--triangle,
        .icon-sort--triangle--right {
            &::before {
                border-bottom-color: #4c4c4c;
            }
        }
    }

    .isDesc {
        .icon-sort,
        .icon-sort--right {
            &::after {
                border-right-color: #4c4c4c;
                border-top-color: #4c4c4c;
            }
        }
        .icon-sort--triangle,
        .icon-sort--triangle--right {
            &::after {
                border-top-color: #4c4c4c;
            }
        }
    }
</style>
